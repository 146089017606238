import { render, staticRenderFns } from "./DroneCreateService.vue?vue&type=template&id=636dbfd1&scoped=true"
import script from "./DroneCreateService.vue?vue&type=script&lang=js"
export * from "./DroneCreateService.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636dbfd1",
  null
  
)

/* custom blocks */
import block0 from "./DroneCreateService.vue?vue&type=custom&index=0&blockType=i18n&locale=th&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports